import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from '../../util/sdk/load-third-javascript';
import { PAY_METHODS_TRANSLATE, CardMode, PAY_METHODS_TYPE } from 'const/pay';
import { payMethods, ORDER_STATUS } from 'const';
import { isSpecialMultiCurrency } from 'utils/multi-currency';
import { pick, isEmpty, set } from 'lodash';
import orderApi from 'api/order';
import { openPayStripeDialog } from 'components/page/pay/pay-stripe';
import { openPayCloverDialog } from 'components/page/pay/pay-clover';
import { openPayScanDialog } from 'components/page/pay/pay-scan';
import { OpenCardFormDialog } from 'components/page/pay/card-form';
import thirdPlatformApi from 'api/third-platform';
import { formatAsSmallestUnit } from '../../util/money';

import { jumpPayUrlAddLongParams, openWindowWithHtmlStr } from 'helper/pay';
import { Notify } from 'zent';
import { CHECKOUT_RULE_TYPE, TEMPLATE_TYPE } from '../../pages/order/component/customized-checkout/const';
import LocalStorage from 'utils/local-storage';

/**
 * 支付的下一步行为-调用payOrder返回的
 */
export const PayNextAction = {
  ThreeDSecure: 'three_d_secure',
  RedirectToUrl: 'redirect_to_url',
  DisplayQrCode: 'display_qr_code',
  UseStripeSdk: 'use_stripe_sdk',
  DisplayHtmlQrcode: 'display_html_qr_code',
};

const PAY_ORDER_ERROR_CODE = {
  ORDER_HAS_PAID: 270102015,
  ORDER_CANCELED: 270102041,
  ORDER_REFUNDED: 270102042,
};

const getFormatPaymentGatewaysList = ({ intl, paymentGatewaysList }) => {
  return paymentGatewaysList.map((item, index) => {
    /** 支付展示名称 */
    const text =
      item?.paymentCategoryName ||
      intl.formatMessage({
        id: PAY_METHODS_TRANSLATE[item?.paymentCategory] || 'none',
        defaultMessage: item?.paymentCategory,
      });
    const paymentProviders = item.paymentProviders.filter((provider) => provider.categoryStatus === 1); // 过滤未启用的支付网关
    const oldData = pick(item, ['logoUrl', 'paymentCategory', 'allPaymentMethods']);

    return {
      ...oldData,
      text,
      value: index,
      paymentProviders, // 过滤未启用的支付网关
    };
  });
};
/**
 * 获取订单结果
 * @param param0
 */
export const useOrderInfo = ({ kdtId, token }) => {
  const [orderInfo, setOrderInfo] = useState({});

  useEffect(() => {
    const getData = () => {
      orderApi.getOrderByToken({ kdtId, token }).then((res) => {
        setOrderInfo(res);
      });
    };

    if (!kdtId || !token) return;
    getData();
  }, [kdtId, token]);

  return {
    orderInfo,
  };
};

/**
 * paymentGateways格式：
 * 外层代表付款类别，paymentProviders字段代表某个付款类别下所有的支付网关
 [{
     logoUrl: "https://b.yzcdn.cn/public_files/72b4dc7c58b2b96b6fa3e859bfe59423.png"
     paymentCategory: "BOGUS_GATEWAY"
     paymentCategoryName: "",
     paymentProviders: [{
        categoryStatus: 1
        gatewayId: 305524
        logo: "https://img.yzcdn.cn/upload_files/2020/10/21/Fj0_VkX2C6bn8_KEr35l3tjzMwwl.png"
        manualName: "Stripe"
        name: "Stripe"
        noMethods: 0
        paymentMethods: [{enabled: 1, logo: "https://su.yzcdn.cn/public_files/60d55738ddfef580dedf24603217d325.png",…},…]
        paymentMode: "direct"
        providerId: 10002
        providerType: 2
        separatePaymentMethod: 0
     }]
 },
 {

 }]

 expressCheckout :是否是快捷支付 其实这个参数购买商品才会有
 * @param param0 
 */
export const usePaySelect = ({ intl, token, kdtId, expressCheckout = 0, currency, multiCurrencyInfo }) => {
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState({}); // 当前选择的付款类别
  const [selectedProvider, setSelectedProvider] = useState({}); // 当前选择的具体网关
  const [stripeComplete, setStripeComplete] = useState(false); // Stripe SDK 加载完成

  const STRIPE = 'Stripe';
  const CLOVER = 'Clover';

  const ENV_URL_MAP = {
    development: THIRD_JAVASCRIPT_CONFIG.CLOVER.devUrl,
    qa: THIRD_JAVASCRIPT_CONFIG.CLOVER.devUrl,
    pre: THIRD_JAVASCRIPT_CONFIG.CLOVER.devUrl,
    prod: THIRD_JAVASCRIPT_CONFIG.CLOVER.url,
  };

  /** 如果第三方支付方式需要加载对应的sdk */
  const loadThirdSdk = (list) => {
    let activeStripe = false;
    let activeClover = false;
    list.forEach((item) => {
      item.paymentProviders.forEach((provider) => {
        if (provider?.name === STRIPE) {
          activeStripe = true;
          // loadThirdJavascript(THIRD_JAVASCRIPT_CONFIG.STRIPE.name, THIRD_JAVASCRIPT_CONFIG.STRIPE.url, false);
        }
        if (provider?.name === CLOVER) {
          activeClover = true;
          // const url =
          //     ENV_URL_MAP[window?.i18n_env?.APPLICATION_STANDARD_ENV] ||
          //     THIRD_JAVASCRIPT_CONFIG.CLOVER.devUrl;
          // loadThirdJavascript(THIRD_JAVASCRIPT_CONFIG.CLOVER.name, url, false);
        }
      });
    });
    if (activeStripe) {
      loadThirdJavascript(THIRD_JAVASCRIPT_CONFIG.STRIPE.name, THIRD_JAVASCRIPT_CONFIG.STRIPE.url, true).then(() => {
        console.log('stripe sdk complete');
        setStripeComplete(true);
      });
    }
    if (activeClover) {
      const url = ENV_URL_MAP[window?.i18n_env?.APPLICATION_STANDARD_ENV] || THIRD_JAVASCRIPT_CONFIG.CLOVER.devUrl;
      loadThirdJavascript(THIRD_JAVASCRIPT_CONFIG.CLOVER.name, url, false);
    }
  };

  /** 设置默认选中的支付网关
   * 若是PayPal快捷支付，选中快捷支付
   * 若不是默认选中第一个
   */
  const setDefaultPaymentGateway = (paymentGatewaysList) => {
    const isExpressCheckoutIndex = paymentGatewaysList.findIndex((item) => {
      const list = item?.paymentProviders.filter((provider) => provider?.name === 'PayPal' && expressCheckout);
      return list?.length;
    });
    // Apple Pay 和 Google Pay 不能为默认支付方式, 因为不一定可用
    const defaultIndex = paymentGatewaysList.findIndex((item) => {
      return (
        item.paymentCategory !== PAY_METHODS_TRANSLATE.GOOGLE_PAY &&
        item.paymentCategory !== PAY_METHODS_TRANSLATE.APPLE_PAY
      );
    });
    const selectedIndex = isExpressCheckoutIndex !== -1 ? isExpressCheckoutIndex : defaultIndex >= 0 ? defaultIndex : 0; // 当前索引
    const paymentGatewayItem = paymentGatewaysList[selectedIndex];
    setSelectedPaymentInfo(paymentGatewayItem);
    setSelectedProvider(paymentGatewayItem?.paymentProviders[0]); // 目前PayPal下，paymentProviders只有一个，所以直接这样写了
  };

  const getPaymentGatewaysByCategory = () => {
    const deviceType = 10;
    const data = { kdtId, deviceType, token };

    if (isSpecialMultiCurrency(multiCurrencyInfo)) {
      data.currency = multiCurrencyInfo.currency || currency;
      return orderApi.getGroupedPaymentGatewaysByCurrency(data);
    } else {
      return orderApi.getGroupedPaymentGateways(data);
    }
  };

  /**
   * 初次进入页面
   * 1. 设置支付网关列表
   * 2. 加载sdk
   * 3. 设置默认支付网关
   */
  useEffect(() => {
    if (!token) return;
    getPaymentGatewaysByCategory().then((res) => {
      const paymentGatewaysList = getFormatPaymentGatewaysList({ paymentGatewaysList: res, intl });
      setPaymentGateways(paymentGatewaysList);
      loadThirdSdk(paymentGatewaysList);
      setDefaultPaymentGateway(paymentGatewaysList);
    });
  }, [token, intl, kdtId]);

  /**
   * 点击一级支付菜单
   */
  const onMenuChange = (event) => {
    const index = event.target.value; // 表索引
    const paymentGatewayItem = paymentGateways[index]; // 获取具体的支付类别信息
    setSelectedPaymentInfo(paymentGatewayItem);
    const curProvider = paymentGatewayItem.paymentProviders?.[0] ?? {}; // 默认选中第一个
    setSelectedProvider(curProvider);
  };

  /**
   * 点击2级支付菜单
   * item 他的父级
   * @param event
   * @param item
   */
  const onSubMenuChange = (event, item) => {
    const gatewayId = Number(event.target.value);
    const curProvider = item?.paymentProviders?.find((provider) => gatewayId === provider.gatewayId);
    setSelectedPaymentInfo(item);
    setSelectedProvider(curProvider);
  };

  return {
    paymentGateways,
    selectedProvider,
    selectedPaymentInfo,
    onMenuChange,
    onSubMenuChange,
    stripeComplete,
  };
};

/**
 * 支付逻辑
 * @param param0
 * selectedProvider 支付网关
 * selectedPaymentInfo 支付网关类别信息
 * expressCheckout 是否是快捷支付
 * orderInfo 订单信息
 * sameAsShippingAddress 账单是否和运费一致，商品支付时候才需要
 */

export const usePayOrder = ({
  intl,
  kdtId,
  token,
  orderInfo, // 订单信息
  selectedProvider, // 支付网关信息
  selectedPaymentInfo, // 支付网关类别信息
  expressCheckout = 0, // 是否是快捷支付
  discountCode = '', // 折扣码
  billingAddress = {}, // 账单地址
  language,
  redirectUrl, // 重定向
  sameAsShippingAddress = 0,
  cancelUrl = `${window.location.origin}/me/order/${token}?cancelUrl=1`, // 取消支付的回调地址
  callBack, // 支付后的回调逻辑，比如清除缓存之类的
  message,
  customizedCheckoutData = [],
  errorCallBack = () => {},
}) => {
  /** 当前支付状态 */
  const [payLoading, setPayLoading] = useState(false);
  const [paypalProviderId, setPaypalProviderId] = useState(''); // 快捷支付
  /** 是否是信用卡直连支付 */
  const isCardDirect =
    selectedPaymentInfo?.paymentCategory === PAY_METHODS_TRANSLATE.CREDIT_CARD &&
    selectedProvider?.paymentMode === CardMode.Direct;

  /** 自定义结账数据处理 */
  const initCustomizedFields = () => {
    if (!isEmpty(customizedCheckoutData)) {
      const fields: any[] = [];
      customizedCheckoutData?.forEach(item => {
        if (!item.visible) return;
        item?.items.forEach(cItem => {
          const values = (item.type === CHECKOUT_RULE_TYPE.idcard && cItem.type === TEMPLATE_TYPE.image) ? [
            {
              k: 'ID_CARD_IS_FRONT',
              v: cItem.ID_CARD_IS_FRONT,
            }, {
              k: 'ID_CARD_IS_REVERSE',
              v: cItem.ID_CARD_IS_REVERSE,
            }
          ] : [
            {
              k: '0',
              v: cItem.value,
            }
          ];
          fields.push({
            id: cItem.id,
            moduleType: item.type,
            moduleId: item.moduleId,
            templateItemType: cItem.type,
            label: cItem.label,
            values,
          });
        })
      });
      return fields;
    }
    return [];
  }

  /** 支付通用参数,不要轻易改 */
  const baseParams = {
    kdtId,
    token,
    redirectUrl, // 支付完成后的结果页
    cancelUrl, // 取消支付后的跳转页面，默认是当前页
    billingAddress, // 账单地址，海外支付这个必要，不然有时候会付钱不成功
    paymentGatewayId: selectedProvider?.gatewayId, // 选择的支付网关
    paymentMethods: selectedProvider?.paymentMethods?.filter((item) => !!item.enabled).map((i) => i.value),
    sameAsShippingAddress, // 账单地址是否和收货地址一致
    discountCode, // 折扣码
    message,
  };
  let timer = null;

  // useEffect(() => {
  //     if (!expressCheckout) return;
  //     const data = { kdtId, providerId: 10001 };
  //     shopApi.getExpressPaymentParam(data).then((res) => {
  //         setPaypalProviderId(res?.paymentGatewayId);
  //     });
  // }, [expressCheckout]);

  const onShowStripe = (publishableKey, clientSecret, accountId) => {
    openPayStripeDialog({
      intl,
      publishableKey,
      clientSecret,
      accountId,
      successRedirectUrl: redirectUrl,
      language,
      token,
    });
  };

  // 查询订单支付状态
  const getOrderStatus = () => {
    orderApi.getOrderStatusByToken({ token }).then((res) => {
      const { financialStatus } = res;
      if (financialStatus === ORDER_STATUS.PAID) {
        clearInterval(timer);
        window.history.replaceState(null, '', `../../me/order/${token}`);
        window.location.href = redirectUrl;
      }
    });
  };

  // 扫码支付
  const showScanPay = (params) => {
    const { paymentMethod = {}, qrCode } = params || {};
    const { name = '' } = paymentMethod;
    if (params.qrCode) {
      if (timer) {
        clearInterval(timer);
      }
      timer = setInterval(() => {
        getOrderStatus();
      }, 2000);

      openPayScanDialog({
        name,
        qrCode,
        intl,
      });
    }
  };

  const payOrder = (params) => {
    setPayLoading(true);
    // 自定义结账信息塞进去
    set(params, 'customizedFields.fields', initCustomizedFields());
    orderApi
      .payOrder(params)
      .then((res) => {
        callBack && callBack();
        // 0元单特殊处理 其实应该后端处理
        if (res?.orderConfirmed) {
          window.history.replaceState(null, '', `../../me/order/${token}`);
          window.location.href = redirectUrl;
          return;
        }
        switch (res?.providerType) {
          case payMethods.STRIPE:
            onShowStripe(res?.publishableKey, res?.clientSecret, res?.accountId);
            break;
          case payMethods.PAYPAL:
            window.history.replaceState(null, '', `../../me/order/${token}`);
            window.location.href = res?.paymentUrl;
            break;
          default:
            const { nextAction = {} } = res;
            if (nextAction?.type === PayNextAction?.RedirectToUrl) {
              const { httpMethod, url, params: nextParams } = nextAction?.redirectToUrl ?? {};
              token && window.history.replaceState(null, '', `../../me/order/${token}`);
              jumpPayUrlAddLongParams({
                url,
                params: nextParams,
                httpMethod,
                token,
              });
            }
            if (nextAction?.type === PayNextAction?.DisplayQrCode) {
              const { qrCode } = nextAction?.displayQrCode ?? {};
              showScanPay({ qrCode });
            }
            if (nextAction?.type === PayNextAction?.DisplayHtmlQrcode) {
              const { htmlQrCode } = nextAction?.htmlQrCode ?? {};
              openWindowWithHtmlStr(htmlQrCode);
            }
        }
      })
      .catch((err) => {
        Notify.error(err?.message);
        if (
          [
            PAY_ORDER_ERROR_CODE.ORDER_HAS_PAID,
            PAY_ORDER_ERROR_CODE.ORDER_CANCELED,
            PAY_ORDER_ERROR_CODE.ORDER_REFUNDED,
          ].includes(err?.code)
        ) {
          setTimeout(() => {
            window.location.replace(`/me/order/${token}`);
          }, 1000);
        } else {
          errorCallBack();
        }
      })
      .finally(() => {
        clearCustomizedCheckoutInfo(token);
        setPayLoading(false);
      });
  };

  // 清楚自定义结账的缓存
  const clearCustomizedCheckoutInfo = (token) => {
    if (!token) return;
    const customizedCheckoutInfo = LocalStorage.get('customizedCheckoutInfo') || {};
    delete customizedCheckoutInfo[token];
    LocalStorage.set('customizedCheckoutInfo', customizedCheckoutInfo, 1);
  }

  const onHandleDirect = (params) => {
    OpenCardFormDialog({
      intl,
      payOrderParams: params,
    });
  };

  /** 快捷支付-会员不会有，暂时没认真写 */
  const payFast = (params) => {
    setPayLoading(true);
    orderApi
      .manualCapturePayment(params)
      .then(() => {
        window.history.replaceState(null, '', `../../me/order/${token}`);
        window.location.href = redirectUrl;
      })
      .finally(() => {
        setPayLoading(false);
      });
  };

  /** Clover 支付 */
  const cloverPayOrder = (params) => {
    thirdPlatformApi.getCredentials({ gatewayId: selectedProvider?.gatewayId }).then((res) => {
      setPayLoading(false);
      openPayCloverDialog({
        cloverKey: res?.credentials?.apiKey,
        params,
        intl,
        onSubmit: (newParams) => {
          payOrder({ ...params, ...newParams });
        },
      });
    });
  };

  /** 商品支付 */
  const onPayGoodsOrder = (params) => {
    if (orderInfo?.financialStatus === ORDER_STATUS.PAID) {
      window.history.replaceState(null, '', `../../me/order/${token}`);
      window.location.href = redirectUrl;
      return;
    }
    const goodsParams = {
      ...pick(baseParams, [
        'kdtId',
        'token',
        'redirectUrl',
        'cancelUrl',
        'billingAddress',
        'paymentGatewayId',
        'paymentMethod',
        'sameAsShippingAddress',
        'discountCode',
        'message',
      ]),
      ...params,
    };
    const isCloverPay = selectedProvider?.providerType === payMethods.CLOVER;
    /** 信用卡直连，走直连的逻辑 */
    if (isCardDirect) {
      onHandleDirect(goodsParams);
    } else if (expressCheckout && Number(selectedProvider?.gatewayId) === paypalProviderId) {
      payFast(goodsParams);
    } else if (isCloverPay) {
      cloverPayOrder(goodsParams);
    } else {
      payOrder(goodsParams);
    }
  };

  /**
   * 会员支付
   */
  const payMemberOrder = () => {
    if (orderInfo?.financialStatus === ORDER_STATUS.PAID) {
      return;
    }
    const memberParams = pick(baseParams, [
      'kdtId',
      'token',
      'redirectUrl',
      'cancelUrl',
      'billingAddress',
      'paymentGatewayId',
      'paymentMethods',
    ]);
    /** 信用卡直连，走直连的逻辑 */
    if (isCardDirect) {
      onHandleDirect(memberParams);
    } else {
      payOrder(memberParams);
    }
  };

  return {
    onPayGoodsOrder,
    payMemberOrder,
    payLoading,
  };
};

let payParams = {};
export const useAppleGooglePay = (props) => {
  const {
    token, // 订单 token
    priceInfo, // 价格信息
    paymentGateways, // 支付网关列表
    selectedProvider,
    stripeComplete, // Stripe SDK 加载完成
    shopSetting,
    validator,
    appleGoogleBtnRef,
  } = props;

  // Stripe SDK 公钥信息
  const [stripeAccountInfo, setStripeAccountInfo] = useState({
    accountId: '',
    publicSecret: '',
  });
  const [applePayStatus, setApplePayStatus] = useState(false); // 是否支持 Apple Pay
  const [googlePayStatus, setGooglePayStatus] = useState(false); // 是否支持 Google Pay
  const [stripeInstance, setStripeInstance] = useState(null); // Stripe 实例
  const [paymentRequest, setPaymentRequest] = useState(null); // Stripe 请求支付对象
  const [payLoading, setPayLoading] = useState(false);

  /** 是否配置了 apple google pay */
  const appleGooglePaymentItem = useMemo(() => {
    return paymentGateways?.find((item) => {
      return (
        item.paymentCategory === PAY_METHODS_TRANSLATE.APPLE_PAY ||
        item.paymentCategory === PAY_METHODS_TRANSLATE.GOOGLE_PAY
      );
    });
  }, [paymentGateways]);

  /** 开启了 Apple Pay 或者 Google Pay 查询 Stripe SDK 信息 */
  useEffect(() => {
    if (appleGooglePaymentItem) {
      orderApi
        .getPaymentPublicKey({
          payMethod: 2,
          gatewayId: appleGooglePaymentItem.paymentProviders?.[0].gatewayId,
        })
        .then((data) => {
          setStripeAccountInfo(data);
        });
    }
  }, [appleGooglePaymentItem]);

  // 实例化 Stripe
  // 判断是否支持 Apple Pay 或者 Google Pay
  // 创建 paymentRequest 对象
  useEffect(() => {
    if (stripeComplete && stripeAccountInfo.publicSecret && !stripeInstance) {
      const stripeInstance = window.Stripe(stripeAccountInfo.publicSecret, {
        stripeAccount: stripeAccountInfo.accountId,
      });
      setStripeInstance(stripeInstance);
      const { countryCode, currency } = shopSetting;
      const stripeRequestParams = {
        country: countryCode,
        currency: currency?.toLowerCase(),
        total: {
          label: token,
          amount: formatAsSmallestUnit(currency, priceInfo.totalPrice),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      };
      // 不可用地区 Stripe paymentRequest 方法会抛错
      try {
        const paymentRequest = stripeInstance.paymentRequest(stripeRequestParams);
        paymentRequest.canMakePayment().then((result) => {
          if (result) {
            console.log(result, '可用支付');
            setApplePayStatus(result.applePay || false);
            setGooglePayStatus(result.googlePay || false);
            setPaymentRequest(paymentRequest);
            listenAppleGooglePay(stripeInstance, paymentRequest);
          } else {
            console.log('Google Apple Pay 不可用');
          }
        });
      } catch {}
    }
  }, [stripeComplete, stripeAccountInfo]);

  // 监听订单变化, 更新 paymentRequest 对象, prButton 对象
  useEffect(() => {
    if (paymentRequest && priceInfo.totalPrice && appleGoogleBtnRef) {
      const { countryCode, currency, name } = shopSetting;
      paymentRequest.update({
        country: countryCode,
        currency: currency?.toLowerCase(),
        total: {
          label: name,
          amount: formatAsSmallestUnit(currency, priceInfo.totalPrice),
        },
      });
      const elements = stripeInstance.elements();
      const prButton = elements.create('paymentRequestButton', {
        paymentRequest,
      });
      prButton.mount('#payment-request-button');
      prButton.on('click', (event) => {
        prButtonClickFn(event, paymentRequest);
      });
      console.log(prButton, '更新 paymentRequest');
    }
  }, [paymentRequest, priceInfo, selectedProvider, shopSetting, appleGoogleBtnRef]);

  // Apple Pay & Google Pay 按钮支付事件
  // Apple Pay 不能阻止默认事件, 会报错, Google Pay 可以阻止默认事件, 前端会先进行支付验证
  const prButtonClickFn = async (event, paymentRequest) => {
    event.preventDefault();
    const { formValidate, payOrderParams } = await validator();
    console.log(formValidate, payOrderParams, 'payOrderParams');
    payParams = payOrderParams;
    if (formValidate) {
      paymentRequest.show();
    }
  };

  // 监听支付回调
  const listenAppleGooglePay = useCallback(
    (stripeInstance, paymentRequest) => {
      paymentRequest.on('paymentmethod', async (ev) => {
        let clientSecret = '';
        try {
          const result = await appleGooglePay();
          clientSecret = result.clientSecret;
        } catch {}

        // 下单失败
        if (!clientSecret) {
          ev.complete('success');
          console.log(ev, 'clientSecret empty');
          setPayLoading(false);
          return;
        }

        console.log(clientSecret, 'clientSecret');
        const { paymentIntent, error: confirmError } = await stripeInstance.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false },
        );
        if (confirmError) {
          console.log(ev, 'fail');
          ev.complete('fail');
          setPayLoading(false);
        } else {
          ev.complete('success');
          // 是否需要其他动作
          if (paymentIntent.status === 'requires_action') {
            // 让 Stripe 处理剩余流程
            const { error } = await stripeInstance.confirmCardPayment(clientSecret);
            if (error) {
              // 付款失败
            } else {
              // 付款成功
              window.history.replaceState(null, '', `../../me/order/${token}`);
              window.location.href = payParams.redirectUrl;
            }
            setPayLoading(false);
          } else {
            window.history.replaceState(null, '', `../../me/order/${token}`);
            window.location.href = payParams.redirectUrl;
            setPayLoading(false);
          }
        }
      });
      paymentRequest.on('cancel', (ev) => {
        setPayLoading(false);
      });
    },
    [stripeInstance],
  );

  /** Apple Pay & Google Pay */
  const appleGooglePay = async () => {
    return orderApi
      .payOrder(payParams)
      .catch((err) => {
        Notify.error(err?.message);
      })
      .finally(() => {
        setPayLoading(false);
      });
  };

  return {
    applePayStatus,
    googlePayStatus,
  };
};
