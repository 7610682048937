import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { OPEN_MODE, MessageType } from 'const';
import { Message } from 'utils/postmessage';
import { initGlobalStyle } from 'utils/set-style';
import { getQueryString } from 'utils';
import { useDecorationActiveMap } from 'hook/decoration-active-map';
import { memoedWrapedSectionsMap } from '../pages/components/decoration-wrapper/index';
import { SectionsMap } from '../pages/design/config/sections_map';
import emitter, { THEME_DATE_CHANGE } from '../event';
import lodashMerge from 'lodash/merge';
import { blockJump } from 'helper/theme';

function getBoundingClientRect(element) {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
  };
}

const useThemeDecoration = (props) => {
  // console.log('props useThemeDecoration', props);
  const [theme, setThemeData] = useState(props.theme || {});
  const [current, setCurrent] = useState('');
  const [sectionOrderInfo, setSectionOrderInfo] = useState(null);
  const [emptyKey, setEmptyKey] = useState(Math.random());
  const { mode } = getQueryString(props.location.search);
  const { initCustomPageVarCss } = props;
  const isEdit = Number(mode) === OPEN_MODE.EDIT_NORMAL;
  // console.log('isEdit', isEdit, mode);
  useEffect(() => {
    props.theme && setThemeData(props.theme);
  }, [props.theme]);

  const contentForIndex = get(theme, 'current.content_for_index', []);
  const sections = get(theme, 'current.sections', {});

  useEffect(() => {
    props.theme && setThemeData(props.theme);
  }, [props.theme]);

  const handleNewMessage = (data, type) => {
    console.log('????', data, type);
    // 尺码表 详情预览的时候也会接受事件导致  isEdit 不是true 而应该是false
    window.isEdit = true;
    if (!data && type !== MessageType.CURRENT_SECTION) return;
    switch (type) {
      case MessageType.CURRENT_SECTION:
        setCurrent(data);
        break;

      case 'StorefrontMessage::SetSectionOrder':
        setThemeData(data.themeData);
        setSectionOrderInfo(data.payload);

        break;
      case MessageType.EDIT_INFO:
        // console.log('data.payload,EDIT_INFO', data);
        window.EDIT_INFO = JSON.parse(data);
        break;
      case 'decoration_b_lang':
        window.decoration_b_lang = JSON.parse(data);
        setEmptyKey(Math.random());
        break;
      case MessageType.SITE_SWITCH: {
        if (theme?.current?.sections?.['site-select']) {
          delete theme.current.sections['site-select'];
        }
        const newThemeData = lodashMerge({}, theme, data);
        setThemeData(newThemeData);
        setTimeout(() => {
          blockJump();
        }, 0);
        break;
      }
      case MessageType.ADMIN_CHANGE:
      default:
        initGlobalStyle(data);
        setThemeData(data);
        initCustomPageVarCss && initCustomPageVarCss(data);
        emitter.emit(THEME_DATE_CHANGE, data);
        break;
    }
  };
  useEffect(() => {
    if (sectionOrderInfo) {
      // 这里存在数据滞后性问题 暂时采用这样的hack解决
      setTimeout(() => {
        const target = document.getElementsByClassName(`section-${sectionOrderInfo.selectedSectionGid}`)[0];
        if (target) {
          target.scrollIntoView({ block: 'start' });
          window.scrollBy(0, -280);
          // 这里需要延时不然获取的位置不准确 需要等滚动完毕 不然getBoundingClientRect获取的数据不准确
          setTimeout(() => {
            window.parent.postMessage(
              {
                type: 'StorefrontMessage::SetSectionOrder',
                payload: getBoundingClientRect(target),
              },
              '*',
            );
          }, 200);
        }
      }, 0);
    }
  }, [sectionOrderInfo]);

  const scrollSectionInView = () => {
    const sectionEl = document.querySelector(`.section-${current}`);
    if (sectionEl) {
      if (current.indexOf('product-show-chocolate') === 0 || current.indexOf('slideshow-video-pic') === 0) {
        sectionEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        sectionEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // 我醉了 为啥后面不滚了呢 intro的兼容
        if (current.indexOf('featured-content') > -1) {
          setTimeout(() => {
            sectionEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            const targetEle = document.querySelector('.wrapper-section-operate.active');
            setTimeout(() => {
              window.parent.postMessage(
                {
                  type: 'StorefrontMessage::IntroPos',
                  payload: getBoundingClientRect(targetEle),
                },
                '*',
              );
            }, 1000);
          }, 1000);
        } else {
          window.parent.postMessage(
            {
              type: 'StorefrontMessage::IntroPos',
              payload: {},
            },
            '*',
          );
        }
      }
    }
  };

  useEffect(() => {
    // 这种模式全部都是从b端传数据过来
    if (isEdit) {
      const message = new Message(props, handleNewMessage);
      return () => {
        message.removeListenMessage();
      };
    }
  }, []);

  useEffect(() => {
    // 加载并展示facebook message
    // triggerFbMessage(true);
    initGlobalStyle(theme);
    initCustomPageVarCss && initCustomPageVarCss(theme);
    // window.parent.postMessage(
    //     {
    //         type: MessageType.HASH_CHANGE,
    //         content: {
    //             pathname: props.location.pathname,
    //         },
    //     },
    //     '*',
    // );
  }, []);

  const { decorationActiveMap, changeDecorationActiveFun } = useDecorationActiveMap(contentForIndex);

  useEffect(() => {
    if (typeof current !== 'undefined') {
      scrollSectionInView();
      changeDecorationActiveFun(current, false);
    }
  }, [current]);

  return {
    theme,
    setThemeData,
    current,
    setCurrent,
    sectionOrderInfo,
    setSectionOrderInfo,
    emptyKey,
    setEmptyKey,
    isEdit,
    decorationActiveMap,
    changeDecorationActiveFun,
    contentForIndex,
    sections,
  };
};

export const renderSectionElem = ({
  item,
  isEdit,
  theme,
  props,
  hashId,
  decorationActiveMap,
  index,
  changeDecorationActiveFun,
  customParams = {}, // 自定义透传参数
  initialData = {}, // 有些组件依赖 initialData 去获取 ktdid等信息 其实这样不好
  history = {},
}) => {
  const Elem = isEdit ? memoedWrapedSectionsMap[item?.type] : SectionsMap[item?.type]?.component;
  let eleProps = {
    ...item,
    theme,
    locale: props.locale,
    key: hashId,
    elemClass: `section-${hashId}`,
    initialData,
    ...customParams,
    history,
  };
  if (isEdit) {
    eleProps = {
      ...eleProps,
      hashId,
      isFirst: index === 0,
      decorationActive: decorationActiveMap[hashId],
      changeDecorationActiveFun,
      initialData,
      ...customParams,
      history,
    };
  }
  const ElemWithProps = <Elem {...eleProps} />;
  return !item?.disabled && Elem ? ElemWithProps : null;
};
export default useThemeDecoration;
